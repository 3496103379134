import {defineNuxtPlugin} from '#app';

export default defineNuxtPlugin(async () => {
	const {PeerTubePlayer} = await import('@peertube/embed-api');
	return {
		provide: {
			PeerTubePlayer,
		},
	};
});
