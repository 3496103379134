const settings = {
	NkCarousel: {
		fixedClasses: {
			wrapper: 'nk-carousel-wrapper',
		},
		variants: {
			default: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: {
						delay: 2500,
					},
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
							centeredSlides: false,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				controls: 'flex justify-between items-center',
				buttonWrapper: '',
				button:
					'transition ease-out duration-300 justify-center items-center text-center text-secondary-element text-xl hover:text-secondary-element--hover focus:text-secondary-element--active hidden sm:inline-flex',
				buttonLeft: 'left-0',
				buttonRight: 'right-0',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5 mx-auto sm:mx-0',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-8 is-active:w-10 h-4 mx-1 rounded bg-secondary-surface hover:bg-secondary-surface--active is-active:bg-secondary-element--active transition',
				paginationButtonActive: 'is-active',
			},
			gallery: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.5,
					centeredSlides: true,
					pagination: false,
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: '',
				slideContent:
					'bg-neurtral-surface h-full flex justify-center items-center relative z-0 overflow-hidden',
				slideBackgroundImage: '',
				buttonWrapper:
					'absolute top-1/2 left-0 w-full flex z-10 justify-between transform -translate-y-1/2 pointer-events-none px-6',
				button:
					'pointer-events-auto rounded transition ease-out duration-300 justify-center items-center text-center text-secondary-on-element text-xl inline-flex bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-8 is-active:w-10 h-4 mx-1 rounded bg-secondary-surface hover:bg-secondary-surface--active is-active:bg-secondary-element--active transition',
				paginationButtonActive: 'is-active',
			},
			news: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 2.5,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: 'sm:overflow-hidden min-h-full h-full',
				sliderItem:
					'border-neutral-border border shadow-md rounded-lg min-h-full h-auto swiper-slide--news',
				slideContent: 'h-full relative overflow-hidden min-h-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 -mt-6 hidden p-2',
				button:
					'pointer-events-auto rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-primary-surface hover:bg-primary-surface--active is-active:bg-primary-element--active transition',
				paginationButtonActive: 'is-active',
			},
			calendar: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: 'sm:overflow-hidden min-h-full h-full',
				sliderItem: '',
				slideContent:
					'h-full flex flex-col justify-between relative z-0 overflow-hidden min-h-full space-y-12',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 hidden -mt-6 p-2',
				button:
					'pointer-events-auto rounded-full transition ease-out duration-300 justify-center items-center text-center text-white text-xl inline-flex border border-white hover:bg-white hover:text-primary-text focus:bg-white focus:text-primary-text focus-visible:scale-[90%] focus-visible:outline-white',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-primary-text-high-contrast hover:bg-primary-text is-active:bg-white transition focus-visible:scale-[90%] focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			hero: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'slide',
					autoplay: {
						delay: 6000,
					},
					slideEffect: {
						shadow: true,
						translate: ['120%', 0, -500],
					},
				},
				wrapper: 'relative w-full bg-secondary-surface',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[1/1] md:aspect-[3/2] lg:aspect-[16/9] xl:aspect-[21/9] max-h-screen',
				slideContent: 'relative z-1 h-full w-full',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: '',
				buttonWrapper:
					'absolute top-2 right-0 hidden sm:flex my-6 px-3 translate-y-1/2 transform z-20 space-x-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-white text-lg hover:text-white/75 focus:text-white border border-white bg-black/80 hidden sm:inline-flex',

				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full bottom-0 block z-10 my-6 translate-y-1/2 transform',
				pagination:
					'flex flex-wrap items-center list-none -mx-1 justify-center',
				paginationButton:
					'block w-8 is-active:w-10 h-4 mx-1 rounded bg-white/50 hover:bg-white/75 is-active:bg-white transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			topthema: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 8000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'h-full min-h-full relative w-full overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem: 'h-full min-h-full',
				slideContent: 'relative z-10 h-full min-h-full',
				controls: '',
				buttonWrapper:
					'absolute top-0 right-0 px-3 translate-y-1/2 transform z-20 space-x-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-white text-lg hover:text-white/75 focus:text-white border border-white bg-black/80 hidden sm:inline-flex',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full bottom-0 block z-10 my-6 translate-y-1/2 transform',
				pagination: 'flex flex-wrap items-center list-none mx-3 justify-end ',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-white/50 hover:bg-white/75 is-active:bg-white transition-all',
				paginationButtonActive: 'is-active',
			},
			'hero-slim': {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 6000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'relative w-full bg-secondary-surface overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[16/9] lg:aspect-[21/9] max-h-[65vh]',
				slideContent:
					'relative z-10 py-10 px-3 max-w-screen-sm mx-auto text-center text-shadow-sm flex h-full items-center justify-center',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: '',
				buttonWrapper:
					'absolute bottom-0 right-0 hidden sm:flex my-6 px-3 translate-y-1/2 transform z-20',
				button:
					'transition ease-out duration-300 justify-center items-center text-center text-white/50 text-xl hover:text-white/75 focus:text-white hidden sm:inline-flex',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full bottom-0 block z-10 my-6 translate-y-1/2 transform',
				pagination:
					'flex flex-wrap items-center list-none -mx-1 justify-center',
				paginationButton:
					'block w-8 is-active:w-10 h-4 mx-1 rounded bg-white/50 hover:bg-white/75 is-active:bg-white transition-all',
				paginationButtonActive: 'is-active',
			},
			bildergalerie: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: false,
					initialSlide: 2,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 hidden -mt-4 p-2',
				button:
					'pointer-events-auto rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-primary-surface hover:bg-primary-surface--active is-active:bg-primary-element--active transition',
				paginationButtonActive: 'is-active',
			},
		},
	},
	NkCard: {
		variants: {
			imageOverlay: {
				wrapper: 'block relative overflow-hidden rounded-lg h-full min-h-full',
				body: 'absolute bottom-0 left-0 w-full px-5 pb-6 pt-10 text-white bg-gradient-to-t from-black/75 to-black/0 text-shadow-sm flex flex-col gap-3',
				header: 'h-full min-h-full',
				image: 'w-full h-full',
				footer: '',
				link: 'transition transform duration-300 ease-out hover:-translate-y-1 hover:scale-[97%] focus-visible:scale-[97%] hover:shadow-md focus:shadow-md',
			},
		},
	},
	NkButton: {
		fixedClasses:
			'inline-flex items-center transition duration-300 ease-out font-medium relative rounded no-underline',
		disabled: 'opacity-50 cursor-not-allowed',
		expand: 'block w-full text-center justify-center',
		loading: 'relative',
		prependIconAttributes: {
			class: 'mr-1',
		},
		appendIconAttributes: {
			class: 'ml-1',
		},
		variants: {
			solid: 'nk-solid',
			outline: 'nk-outline border',
			link: 'nk-link border-b-2 rounded-none',
			ghost: 'nk-ghost',
		},
		colors: {
			primary:
				'[&.nk-solid]:bg-primary-element [&.nk-solid]:hover:bg-primary-element--hover [&.nk-solid]:focus:bg-primary-element--active [&.nk-solid]:text-primary-on-element [&.nk-link]:border-primary-element [&.nk-link]:text-primary-text [&.nk-link]:hover:text-primary-text--hover [&.nk-link]:hover:border-primary-element--hover [&.nk-ghost]:text-primary-text [&.nk-ghost]:hover:text-primary-text--hover [&.nk-ghost]:hover:border-primary-element--hover [&.nk-outline]:border-primary-element [&.nk-outline]:text-primary-text [&.nk-outline]:hover:text-primary-text--hover [&.nk-outline]:hover:border-primary-element--hover',
			secondary:
				'[&.nk-solid]:bg-secondary-element [&.nk-solid]:hover:bg-secondary-element--hover [&.nk-solid]:focus:bg-secondary-element--active [&.nk-solid]:text-secondary-on-element [&.nk-link]:border-secondary-element [&.nk-link]:text-secondary-text [&.nk-link]:hover:text-secondary-text--hover [&.nk-link]:hover:border-secondary-element--hover [&.nk-ghost]:text-secondary-text [&.nk-ghost]:hover:text-secondary-text--hover [&.nk-ghost]:hover:border-secondary-element--hover [&.nk-outline]:border-secondary-element [&.nk-outline]:text-secondary-text [&.nk-outline]:hover:text-secondary-text--hover [&.nk-outline]:hover:border-secondary-element--hover',
			neutral:
				'[&.nk-solid]:bg-neutral-element [&.nk-solid]:hover:bg-neutral-element--hover [&.nk-solid]:focus:bg-neutral-element--active [&.nk-solid]:text-neutral-on-element [&.nk-link]:border-neutral-element [&.nk-link]:text-neutral-text [&.nk-link]:hover:text-neutral-text--hover [&.nk-link]:hover:border-neutral-element--hover [&.nk-ghost]:text-neutral-text [&.nk-ghost]:hover:text-secondary-text--hover [&.nk-ghost]:hover:border-neutral-element--hover [&.nk-outline]:border-neutral-element [&.nk-outline]:text-neutral-text [&.nk-outline]:hover:text-neutral-text--hover [&.nk-outline]:hover:border-neutral-element--hover',
			danger:
				'[&.nk-solid]:bg-danger-element [&.nk-solid]:hover:bg-danger-element--hover [&.nk-solid]:focus:bg-danger-element--active [&.nk-solid]:text-danger-on-element [&.nk-link]:border-danger-element [&.nk-link]:text-danger-text [&.nk-link]:hover:text-danger-text--hover [&.nk-link]:hover:border-danger-element--hover [&.nk-ghost]:text-danger-text [&.nk-ghost]:hover:text-danger-text--hover [&.nk-ghost]:hover:border-danger-element--hover [&.nk-outline]:border-danger-element [&.nk-outline]:text-danger-text [&.nk-outline]:hover:text-danger-text--hover [&.nk-outline]:hover:border-danger-element--hover',
			success:
				'[&.nk-solid]:bg-success-element [&.nk-solid]:hover:bg-success-element--hover [&.nk-solid]:focus:bg-success-element--active [&.nk-solid]:text-success-on-element [&.nk-link]:border-success-element [&.nk-link]:text-success-text [&.nk-link]:hover:text-success-text--hover [&.nk-link]:hover:border-success-element--hover [&.nk-ghost]:text-success-text [&.nk-ghost]:hover:text-success-text--hover [&.nk-ghost]:hover:border-success-element--hover [&.nk-outline]:border-success-element [&.nk-outline]:text-success-text [&.nk-outline]:hover:text-success-text--hover [&.nk-outline]:hover:border-success-element--hover',
			info: '[&.nk-solid]:bg-info-element [&.nk-solid]:hover:bg-info-element--hover [&.nk-solid]:focus:bg-info-element--active [&.nk-solid]:text-info-on-element [&.nk-link]:border-info-element [&.nk-link]:text-info-text [&.nk-link]:hover:text-info-text--hover [&.nk-link]:hover:border-info-element--hover [&.nk-ghost]:text-info-text [&.nk-ghost]:hover:text-info-text--hover [&.nk-ghost]:hover:border-info-element--hover [&.nk-outline]:border-info-element [&.nk-outline]:text-info-text [&.nk-outline]:hover:text-info-text--hover [&.nk-outline]:hover:border-info-element--hover',
			warning:
				'[&.nk-solid]:bg-warning-element [&.nk-solid]:hover:bg-warning-element--hover [&.nk-solid]:focus:bg-warning-element--active [&.nk-solid]:text-warning-on-element [&.nk-link]:border-warning-element [&.nk-link]:text-warning-text [&.nk-link]:hover:text-warning-text--hover [&.nk-link]:hover:border-warning-element--hover [&.nk-ghost]:text-warning-text [&.nk-ghost]:hover:text-warning-text--hover [&.nk-ghost]:hover:border-warning-element--hover [&.nk-outline]:border-warning-element [&.nk-outline]:text-warning-text [&.nk-outline]:hover:text-warning-text--hover [&.nk-outline]:hover:border-warning-element--hover',
			white:
				'[&.nk-solid]:bg-white-element [&.nk-solid]:hover:bg-white-element--hover [&.nk-solid]:focus:bg-white-element--active [&.nk-solid]:text-white-on-element [&.nk-link]:border-white-element [&.nk-link]:text-white-text [&.nk-link]:hover:text-white-text--hover [&.nk-link]:hover:border-white-element--hover [&.nk-ghost]:text-white-text [&.nk-ghost]:hover:text-white-text--hover [&.nk-ghost]:hover:border-white-element--hover [&.nk-outline]:border-white-element [&.nk-outline]:text-white-text [&.nk-outline]:hover:text-white-text--hover [&.nk-outline]:hover:border-white-element--hover',
		},
		sizes: {
			default: 'px-3 py-2 min-h-6 text-base',
			sm: 'px-3 py-2 text-sm',
			md: 'p-3 text-md',
			lg: 'p-3 text-lg',
			collapse: 'p-0',
		},
	},
	NkBox: {
		variants: {
			default: 'bg-neutral-surface px-4 py-3 mb-3',
			transparent: 'px-4 py-3',
		},
	},
	NkDrilldown: {
		fixedClasses: '',
		variants: {
			default: {
				menu: 'nk-drilldown bg-neutral-surface rounded overflow-hidden',
				menuInner: 'list-none',
				menuItem: 'border-b border-neutral-border last:border-0',
				menuItemButton:
					'relative flex w-full py-4 px-4 text-primary-text hover:bg-neutral-surface-hover',
				menuItemButtonActive:
					'border-b border-neutral-border text-primary-text',
				menuItemIcon: 'no-underline before:no-underline after',
				menuItemButtonIcon:
					'ml-auto no-underline absolute right-0 mr-3 transform transition-transform duration-300 ease-in-out ',
				menuItemButtonIconActive: 'rotate-180',
				menuItemLink:
					'block py-4 px-4 link-active:bg-primary-element link-active:text-primary-on-element link-active-exact:bg-primary-element link-active-exact:text-primary-on-element group-[.is-submenu]:pl-8',
				menuItemLinkIcon: 'ml-auto no-underline before:no-underline',
				submenu: 'bg-neutral-surface--active group is-submenu',
			},
		},
	},
};

export {settings};
