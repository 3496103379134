import revive_payload_client_4sVQNw7RlN from "/builds/webcontact/rheinau/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/webcontact/rheinau/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/webcontact/rheinau/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/webcontact/rheinau/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/webcontact/rheinau/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/webcontact/rheinau/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/webcontact/rheinau/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/webcontact/rheinau/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/webcontact/rheinau/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/builds/webcontact/rheinau/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_03YjkxYbK5 from "/builds/webcontact/rheinau/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import matomo_client_u58QsgwcV0 from "/builds/webcontact/rheinau/node_modules/@webcontact/nuxt-kommunen/dist/runtime/plugins/matomo.client.mjs";
import error_handler_cptaiF04fI from "/builds/webcontact/rheinau/node_modules/@webcontact/nuxt-kommunen/dist/runtime/plugins/error-handler.mjs";
import vee_validate_gJ9GDooMl7 from "/builds/webcontact/rheinau/node_modules/@webcontact/nuxt-kommunen/dist/runtime/plugins/vee-validate.mjs";
import sentry_client_TgrSXJLeIY from "/builds/webcontact/rheinau/node_modules/@webcontact/nuxt-kommunen/dist/runtime/plugins/sentry.client.mjs";
import nuxt_kommunen_5mU9tAAkRV from "/builds/webcontact/rheinau/node_modules/@webcontact/nuxt-kommunen/dist/runtime/plugins/nuxt-kommunen.mjs";
import peertube_client_BTsy1ConwB from "/builds/webcontact/rheinau/plugins/peertube.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  nuxt_plugin_03YjkxYbK5,
  matomo_client_u58QsgwcV0,
  error_handler_cptaiF04fI,
  vee_validate_gJ9GDooMl7,
  sentry_client_TgrSXJLeIY,
  nuxt_kommunen_5mU9tAAkRV,
  peertube_client_BTsy1ConwB
]